<template>
  <el-container class="power_container">
    <el-aside width="180px">
      <div class="aside_title">
        <i class="el-icon-s-operation"></i>
        <span>系统设置</span>
      </div>
      <div class="home_title" @click="homeJump">
        <i class="el-icon-s-home"></i>
        <span>返回首页</span>
      </div>
      <el-menu
        default-active="/uright"
        class="el-menu-vertical-demo"
        background-color="#2E4052"
        text-color="#fff"
        active-text-color="#409EFF"
        router
      >
        <el-menu-item index="/uright" style="padding-left: 5px">
          <i class="el-icon-menu"></i>
          <span slot="title">角色权限</span>
        </el-menu-item>
        <el-menu-item index="/urole" style="padding-left: 5px">
          <i class="el-icon-document"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header height="70px">丰南纵横码头智慧航道可视化平台</el-header>
      <el-main>
        <!-- 路由占位符 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  // 组件名称
  name: 'Power',
  data() {
    return {}
  },
  methods: {
    homeJump() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.power_container {
  position: absolute;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 99;
  .aside_title {
    box-sizing: border-box;
    height: 70px;

    line-height: 70px;
    border-bottom: 2px solid rgba(100, 97, 97, 0.8);
    i {
      font-size: 18px;
      padding-right: 10px;
    }
  }
  .home_title {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 180px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border-top: 2px solid rgba(100, 97, 97, 0.8);
    cursor: pointer;
    i {
      font-size: 18px;
      padding-right: 6px;
    }
  }
  .checkTitle {
    color: #fff;
  }
  .powerCheckList {
    display: flex;
    background-color: #363d45;
    margin: 0px 20px;
    border-radius: 10px;
    .checkListItem {
      box-sizing: border-box;
      padding: 5px 15px;
      border-right: 1px solid #000;
    }
  }
}
.el-header {
  background-color: #1f2329;
  color: #fff;
  text-align: left;
  line-height: 70px;
  border-bottom: 2px solid #000;
  font-size: 18px;
  font-weight: 900;
}

.el-aside {
  background-color: #2e4052;
  text-align: center;
}

.el-main {
  background-color: #1f2329;
  color: #fff;
  text-align: left;
  line-height: 60px;
  padding: 20px 50px;
  border-bottom: 1px solid #1f2329;
}

::v-deep .el-breadcrumb {
  margin-bottom: 15px;
}
::v-deep .el-breadcrumb__inner {
  color: #fff;
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #fff;
}
::v-deep .el-breadcrumb__separator {
  color: #fff;
}
::v-deep .el-card {
  background-color: #2c323a;
  border: none;
}
::v-deep .el-radio__label {
  font-size: 18px;
}
::v-deep .el-radio__input {
  line-height: 2;
}
.el-card {
  background-color: #2c323a;
  border: none;
}
.el-table {
  color: #fff;
}
::v-deep .el-table th {
  background-color: #363d45;
}
::v-deep .el-table tr {
  background-color: #363d45;
}
::v-deep .el-table__body tr.hover-row > td {
  background-color: #6f8091;
}
.el-table__body tr.current-row > td {
  background-color: #a0cfff;
}
// ::v-deep .el-table td.el-table__cell {
// }
.el-icon-edit,
.el-icon-view {
  margin-right: 20px;
  cursor: pointer;
}
</style>
